import { Component } from '@angular/core';

@Component({
  selector: 'metallgehalt-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent {
  blogEntries = [
    {
      headline: 'Vorläufige Zahlen für Erhöhung in 2026 eingepflegt',
      date: '16.11.2024',
      paragraphs: [
        {
          content:
            'Die zahlen für 2026 sind nun auch verfügbar. Sie sind genau so berechnet, wie die für 2025. Lediglich die Zahlen für Bayern sind bereits aus offiziellen Tabellen.'
        }
      ]
    },
    {
      headline: 'Vorläufige Zahlen für Erhöhung in 2025 eingepflegt',
      date: '15.11.2024',
      paragraphs: [
        {
          content:
            'Da es noch keine offiziellen Tabellen gibt, habe ich die Zahlen für 2025 selbst berechnet und zur Verfügung gestellt. In der Regel werden die entgültigen Zahlen nochmal gerundet und weichen höchst Wahrscheinlich leicht von meinen jetzt berechneten Zahlen ab.'
        }
      ]
    },
    {
      headline: 'Tarifabschluss für die Bezirke Bayern und Küste',
      date: '12.11.2024',
      paragraphs: [
        {
          content:
            'Die IG Metall-Bezirke Bayern und Küste haben einen Pilotabschluss in den Tarifverhandlungen erreicht. Dieser Pilotabschluss muss von anderen IG Metall-Bezirke noch über sogenannte Übernahmeverhandlungen übernommen werden. Alle Infos findet ihr bereits unter <a href="/blog/tarifrunden">Tarifrunden</a> Falls ihr Tariftabellen findet, gerne per Mail an mich schicken: tariftabellen@metallgehalt.de'
        }
      ]
    },
    {
      headline: 'Alle Tariftabellen für die Entgelterhöhung ab 01.05.2024 sind nun verfügbar',
      date: '25.04.2023',
      paragraphs: [
        {
          content: 'Die IG Metall hat die offiziellen Tariftabellen veröffentlicht. Sie sind nun im Rechner verfübar.'
        }
      ]
    },
    {
      headline: 'Dynamischer Faktor für Urlaubsgeld in verschiedenen Regionen',
      date: '18.09.2023',
      paragraphs: [
        {
          content:
            'Es scheint in verschiedenen Regionen verschiedene Berechnungen des Urlaubsgeldes zu geben. Daher wird jetzt für jede Region außer NRW ein Prozentsatz von 69% eines Monatseinkommens angenommen. Falls ihr wisst, dass in eurer Region ein anderer Prozentsatz genutzt wird, schreibt mir eine kurze Mail. Dann passe ich das an. Vielen Dank!'
        }
      ]
    },
    {
      headline: 'Tariftabellen für die nächste Entgelterhöhung ab 01.05.2024 in Berlin/Brandenburg eingepflegt',
      date: '18.08.2023',
      paragraphs: [
        {
          content: 'Vielen Dank an C. für die Info über die Tariftabelle für Berlin/Brandenburg ab Mai 2024! Die Daten sind jetzt verfügbar!'
        }
      ]
    },
    {
      headline: 'Weihnachtsgeld kann nun auch frei konfiguriert werden',
      date: '18.08.2023',
      paragraphs: [
        {
          content:
            'Aufgrund meherer Rückmeldungen, dass in manchen Unternehmen der prozentuale Anteil des Weihnachtsgeld von dem im Tarifvertrag abweicht, kann man ab jetzt neben den bestehenden auswählbaren Optionen den Wert auch selber eintippen.'
        }
      ]
    },
    {
      headline: 'Tariftabellen für die nächste Entgelterhöhung ab 01.05.2024 in Baden-Württemberg eingepflegt',
      date: '01.08.2023',
      paragraphs: [
        {
          content: 'Vielen Dank an M.B. für die Info über die Tariftabelle für Baden-Württemberg ab Mai 2024! Die Daten sind jetzt verfügbar!'
        }
      ]
    },
    {
      headline: 'Tariftabellen für die nächste Entgelterhöhung ab 01.05.2024 in Hamburg/Unterweser und Schleswig-Holstein, Mecklenburg-Vorpommern und Niedersachsen eingepflegt',
      date: '16.06.2023',
      paragraphs: [
        {
          content: 'Dank eines aufmerksamen Users konnte ich nun die nächsten Entgelttabellen einpflegen. Vielen Dank dafür!'
        }
      ]
    },
    {
      headline: 'Tariftabellen für die nächste Entgelterhöhung ab 01.05.2024 in Bayern eingepflegt',
      date: '14.05.2023',
      paragraphs: [
        {
          content:
            'Nach einiger Recherche konnte ich die Entgelttabellen ab 01.05.2024 für Bayern finden. Falls du Entgelttabellen ab 01.05.2024 für andere Regionen oder Bundestländer findest oder kennst schick mir gerne eine mail an tariftabellen@metallgehalt.de. Vielen Dank :-)'
        }
      ]
    },
    {
      headline: 'Tariftabellen für die nächste Entgelterhöhung ab 01.05.2024 in NRW eingepflegt',
      date: '10.05.2023',
      paragraphs: [
        {
          content:
            'Ich habe soeben die Daten für die nächste Entgelterhöhung ab 01.05.2024 in Nordrhein-Westfalen eingepflegt. Leider sind die Entgelttabellen für die weiteren Bundesländer und Regionen noch nicht offiziell von der IG Metall veröffentlicht worden. Zumindest konnte ich sie nicht finden. Sie werden nachgepflegt, sobald sie veröffentlicht wurden.'
        }
      ]
    },
    {
      headline: 'Wie berechnet sich das Jahresgehalt im IG Metall Metall- und Elektrotarifvertrag?',
      date: '02.05.2023',
      paragraphs: [
        {
          content:
            'Das Jahresgehalt im IG Metall Metall- und Elektrotarifvertrag ergibt sich aus verschiedenen Komponenten. Diese sind im Tarifvertrag festgelegt und setzen sich aus dem monatlichen Grundgehalt, dem Tariflichen Transformationsgeld (T-Zug T), Urlaubs- und Weihnachtsgeld sowie Tariflichen Zusatzgeldern A und B zusammen.'
        },
        {
          headline: 'Monatliches Grundgehalt',
          content:
            'Das Monatliche Grundgehalt setzt sich aus zwei Komponenten zusammen. Zum einen das ERA Entgelt, welches sich aus der Tariftabelle ablesen lässt und eine darauf basierende Leistungszulage. Diese Leistungszulage berechnet sich prozentual auf das ERA Entgelt. In der Regel gibt es einen maximalen Prozentsatz für die Leistungszulage (z.B. in NRW 20%). Betriebsdurchschnittlich muss die Leistungszulage die hälfte des maximalen Prozentsatz betragen. In NRW wären das somit 10%.'
        },
        {
          headline: 'Tarifliches Transformationsgeld (T-Zug T)',
          content: 'Das T-Zug T wird im Februar ausgezahlt und beträgt 18,4% des monatlichen Grundgehalts inklusive Leistungszulage und Überstundenzuschlag.'
        },
        {
          headline: 'Urlaubsgeld',
          content:
            'Das Weihnachtsgeld berechnet sich durch 2,4% pro Urlaubstag => 0,72% eines Monatseinkommens inklusive Leistungszulage und Überstundenzuschlag. Es wird in der Regel im Juni oder im Juli ausgezahlt. Es gibt allerdings auch Firmen, die im folge Monat des genommenen Urlaubs den entsprechenden Anteil auszahlt. Wenn man also im Januar 8 Urlaubstage genommen hat, bekommt man mit der Januarabrechnung diese 8 * 2,4% => 19,2% eines Monatseinkommens ausbezahlt.'
        },
        {
          headline: 'Tarifliches Zusatzgeld A (T-Zug A)',
          content:
            'Die Sonderzahlung Tarifliches Zusatzgeld A beträgt 27,5% des monatlichen Grundgehalts inklusive Leistungszulage und Überstundenzuschlag. Für Mitarbeiter mit Kindern ist es in 8 freie Tage Umwandelbar. Diese Umwandlung muss entsprechend beantragt werden.'
        },
        {
          headline: 'Tarifliches Zusatzgeld B (T-Zug B)',
          content:
            'Das Tarifliche Zusatzgeld B beträgt 12,3% des Eckentgelts ohne Leistungszulage und ohne Überstundenzuschlag. Das Eckentgelt oder die sogenannte "Ecke" bezeichnet das Grundentgelt, das ein vollzeitbeschäftigter Angestellter der Metall- und Elektroindustrie nach einem Jahr Betriebszugehörigkeit und entsprechender Eingruppierung erhält. Es dient als Orientierungspunkt für die Gehaltsstruktur innerhalb des Tarifvertrags und wird jährlich angepasst. Das Eckentgelt ist somit ein wichtiger Bestandteil der tariflichen Entlohnung und beeinflusst maßgeblich die Höhe des Gehalts der Beschäftigten in der Branche.Es kann vom Arbeitgeber im Einvernehmen mit dem Betriebsrat um bis zu zwei Monate verschoben werden.'
        },
        {
          headline: 'Weihnachtsgeld',
          content:
            'Das Weihnachtsgeld berechnet sich auf das Monatseinkommen inklusive Leistungszulage. Es ist nach Betriebszugehörigkeit gestaffelt. Ausgehend von 25% steigt es pro Jahr Betriebszugehörigkeit um 10% bis es letztendlich bei 55% gedeckelt ist.'
        }
      ]
    }
  ];
}
