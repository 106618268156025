<div class="container py-3">
  <div class="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom">
    <a [routerLink]="['/']" class="d-flex align-items-center text-dark text-decoration-none brand-logo">
      <h1>Metall Gehalt</h1>
      <p>Dein online Gehaltsrechner</p>
    </a>

    <nav class="d-inline-flex mt-2 mt-md-0 ms-md-auto d-none d-md-block">
      <a class="me-3 py-2 text-dark text-decoration-none" [routerLink]="['/']"> Rechner </a>
      <a class="me-3 py-2 text-dark text-decoration-none" [routerLink]="['/blog/tarifrunden']"> Tarifrunden </a>
      <a class="me-3 py-2 text-dark text-decoration-none" [routerLink]="['/blog']"> Blog </a>
      <a class="me-3 py-2 text-dark text-decoration-none" [routerLink]="['/impressum']"> Impressum </a>
      <a class="me-3 py-2 text-dark text-decoration-none" [routerLink]="['/datenschutz']"> Datenschutz </a>
    </nav>
  </div>
  <router-outlet></router-outlet>
  <hr />
  <div class="row my-3">
    <div class="col-md d-flex flex-column align-items-center">
      <div>
        <div>
          <p class="m-0">
            Verdiene mit <strong>TradeRepublic</strong> 3.25% Zinsen auf dein Kapital bei einer voll lizenzierten und von der BaFin regulierten Bank aus Deutschland.
            <a href="https://traderepublicbank.sjv.io/c/4226892/2008585/23226?productName=BROKERAGE&countryCode=DE" target="_blank">Hier klicken</a> (Affiliate Link)
          </p>
        </div>
      </div>
    </div>
  </div>

  <metall-rechner-google-adsense dataAdClient="ca-pub-2750538732947634" dataAdSlot="6523777521" dataAdFormat="fluid"></metall-rechner-google-adsense>

  <footer class="pt-4 my-md-1 pt-md-5 border-top">
    <div class="row">
      <div class="col-6 col-md">
        <h2>Infos</h2>
        <ul class="list-unstyled text-small">
          <li class="mb-1">
            <a class="me-3 py-2 text-dark text-decoration-none" [routerLink]="['/blog/tarifrunden']"> Tarifrunden </a>
          </li>
          <li class="mb-1">
            <a class="me-3 py-2 text-dark text-decoration-none" [routerLink]="['/blog']"> Blog </a>
          </li>
          <li class="mb-1">
            <a class="me-3 py-2 text-dark text-decoration-none" [routerLink]="['/impressum']">Impressum</a>
          </li>
          <li class="mb-1">
            <a class="me-3 py-2 text-dark text-decoration-none" [routerLink]="['/datenschutz']">Datenschutz</a>
          </li>
        </ul>
      </div>
      <div class="col-6 col-md">
        <h2>Unterstützen</h2>
        <ul class="list-unstyled text-small">
          <li class="mb-1">
            <a class="me-3 py-2 text-dark text-decoration-none" target="_blank" href="https://www.amazon.de/visabounty?tag=metallgehalt-21">
              Kostenlose Amazon Visa Karte (Affiliate)
            </a>
          </li>
          <li class="mb-1">
            <a class="me-3 py-2 text-dark text-decoration-none" target="_blank" href="https://www.buymeacoffee.com/maxcoffee"> Buy me a coffee </a>
          </li>
          <li class="mb-1">
            <a class="me-3 py-2 text-dark text-decoration-none" target="_blank" href="https://www.financeads.net/tc.php?t=59466C377272598T"> Finanzguru (Affiliate) </a>
          </li>
          <li class="mb-1">
            <a class="me-3 py-2 text-dark text-decoration-none" target="_blank" href="https://www.financeads.net/tc.php?t=59466C332661946T"> Zasta Steuererklärung (Affiliate) </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</div>
